/* eslint-disable jsx-a11y/alt-text */
import { Content } from "content";
import { Logo } from "logo";
import { NavLinks } from "nav-links";
import useBreakpoint from "use-breakpoint";
import { BREAKPOINTS, mobileBreakpointMediaQuery, palette } from "utils";
import BlackDecorationLinesShort from "img/Short black vertical lines.png";
import styled from "styled-components";
import { useState } from "react";

const Wrapper = styled.div<{ useH1: boolean; scrollY: number }>(
  ({ useH1, scrollY }) => ({
    paddingTop: 29,
    paddingBottom: 25,
    borderBottom: useH1 ? "none" : "2px rgba(255, 255, 255, 0.1) solid",
    zIndex: useH1 ? 10 : 0,
    position: useH1 ? "fixed" : "relative",
    top: 0,
    width: "100%",
    background: useH1 ? palette.red : "transparent",
    transition: "opacity 0.3s",
    opacity: useH1 ? (scrollY > 200 ? 1 : 0) : 1,
    pointerEvents: useH1 ? (scrollY > 200 ? "auto" : "none") : "auto",
    [mobileBreakpointMediaQuery]: {
      paddingTop: 20,
      paddingBottom: 20,
    },
  })
);

const Grid = styled.div({
  display: "grid",
  gridTemplateColumns: "auto 1fr",
  position: "relative",
});

export const Header = ({ useH1 = false }) => {
  const { breakpoint } = useBreakpoint(BREAKPOINTS, "desktop");
  const [scrollY, setScrollY] = useState(0);

  document.addEventListener("scroll", () => {
    setScrollY(window.scrollY);
  });

  return (
    <Wrapper {...{ useH1, scrollY }} id="header">
      <Content>
        {breakpoint === "desktop" && (
          <img
            className="small-lines-decoration"
            src={BlackDecorationLinesShort}
          />
        )}
        <Grid>
          {useH1 ? (
            <h1
              style={{
                margin: 0,
                height: breakpoint === "mobile" ? 35 : "auto",
              }}
            >
              <Logo />
            </h1>
          ) : (
            <Logo />
          )}
          <NavLinks />
        </Grid>
      </Content>
    </Wrapper>
  );
};
