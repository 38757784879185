import styled from "styled-components";

export const BREAKPOINTS = { mobile: 0, desktop: 1240 };
export const mobileBreakpointMediaQuery =
  "@media (max-width: " + (BREAKPOINTS.desktop - 1) + "px)";
export const palette = {
  red: "#c33d3c",
};

export const tripleSpaces = (string = "") => {
  return string.replace(/ /g, "   ");
};

export const tripleSpaceChildren = (children: any[]) => {
  if (Array.isArray(children)) {
    return children.map((child) => {
      if (typeof child === "string") {
        return tripleSpaces(child);
      }
      return child;
    });
  }
  if (typeof children === "string") {
    return tripleSpaces(children);
  }
  return children;
};

export const TripleSpaced = ({ children }) => (
  <div style={{ whiteSpace: "pre" }}>{tripleSpaceChildren(children)}</div>
);

export const Spacer = styled.div<{ distance: number }>(({ distance }) => ({
  marginTop: distance,
}));

export const Uppercase = styled.span({ textTransform: "uppercase" });

export const FontIconWrapper = styled.div({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

export const FontIcon = styled.div({
  textTransform: "uppercase",
  fontFamily: `"Monoton", cursive`,
  fontSize: 72,
  color: palette.red,
  fontWeight: 400,
  margin: "0 auto",
  textAlign: "center",
  marginBottom: -14,
});

export const FontIconText = styled.span({
  fontWeight: 400,
  fontSize: 14,
  fontFamily: `"Montserrat", sans-serif`,
  color: "#fff",
  lineHeight: 1.1,
  textTransform: "uppercase",
  textAlign: "center",
});

export const Bold = styled.span({
  fontWeight: 700,
});

export const VerticalMiddleAlign = styled.div({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
});
