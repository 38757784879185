import styled from "styled-components";
import {
  mobileBreakpointMediaQuery,
  palette,
  tripleSpaceChildren,
} from "utils";

type color = "white" | "black" | "red";

const Header = styled.h3<{
  $alignment: "left" | "center";
  color: color;
  size?: 35 | 70;
  width: number | string;
}>(({ $alignment, color, size, width }) => ({
  textTransform: "uppercase",
  fontFamily: `"Monoton", cursive`,
  fontSize: size ?? 35,
  color: color === "red" ? palette.red : color,
  fontWeight: 400,
  margin: "0 auto",
  whiteSpace: "pre",
  textAlign: $alignment,
  width: width,
  marginBottom: 8,
  [mobileBreakpointMediaQuery]: {
    width: "100%",
    whiteSpace: "pre-wrap",
    maxWidth: 600,
  },
}));

type props = {
  children: any;
  alignment?: "left" | "center";
  color?: color;
  size?: 35 | 70;
  width?: number | string;
};

export const Headline = ({
  children,
  alignment = "center",
  color = "white",
  size = 35,
  width = 600,
}: props) => {
  return (
    <Header {...{ $alignment: alignment, color, size, width }}>
      {tripleSpaceChildren(children)}
    </Header>
  );
};
