/* eslint-disable jsx-a11y/alt-text */ // This is not nice, but I don't have time to make it nice
import "./App.css";
import KokoLogo from "img/koko-logo.svg";
import ReActLogo from "img/react-logo.svg";
import React from "react";
import {
  Bold,
  BREAKPOINTS,
  FontIcon,
  FontIconText,
  FontIconWrapper,
  mobileBreakpointMediaQuery,
  Spacer,
  Uppercase,
  VerticalMiddleAlign,
} from "utils";
import { Section } from "section";
import { Content } from "content";
import { Headline } from "headline";
import { Paragraph, Text } from "text";
import { Timeline } from "timeline";
import { Footer } from "footer";
import { InstructionsCarousel } from "instructions-carousel";
import { TwoColumn } from "TwoColumn";
import useBreakpoint from "use-breakpoint";
import { Header } from "header";
import { SplashSection } from "splash-section";
import styled from "styled-components";

const Video = styled.div({
  margin: "0 auto",
  paddingTop: 30,
  width: 1200,
  height: 675,
  [mobileBreakpointMediaQuery]: {
    width: 335,
    height: 188,
    margin: "0 auto",
  },
});

function App() {
  const { breakpoint } = useBreakpoint(BREAKPOINTS, "desktop");

  return (
    <div className="App">
      <header>
        <Header useH1 />
      </header>

      <Section background="X" id="ceremony">
        <Content>
          <Headline size={breakpoint === "desktop" ? 70 : 35} width="100%">
            Avslutnings&shy;gala
          </Headline>
          <Paragraph>
            <Text size={20}>Fredag 2:a april klockan 17:00</Text>
          </Paragraph>
          <Video>
            <iframe
              width="100%"
              height="100%"
              src="https://www.youtube-nocookie.com/embed/EscTsUx9ev8?rel=0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              frameBorder="0"
              title="Norrtälje Dance Challenge promotion video"
            ></iframe>
          </Video>
        </Content>
      </Section>

      <section className="section1" id="section1">
        <SplashSection />
      </section>

      <Section background="N" id="section2">
        <Content>
          <Headline>
            Tävlingen för&nbsp;&nbsp;&nbsp;alla
            <br />
            som bor i Norrtälje
          </Headline>
          <Paragraph>
            Känner du dig rastlös hemma? Pirrar det i benen? Har du någonsin
            tänkt ”Där vill jag dansa” på vägen hem? Nu har du anledning att
            dansa ute på gatan och inspirera andra att göra samma sak. Var med
            på vår dans-CHALLENGE!
          </Paragraph>
          <Spacer distance={72} />
          <Timeline />
        </Content>
      </Section>

      <Section background="O" id="section3">
        <Content align="left">
          <Headline alignment="left">Gör så här</Headline>
          <Paragraph alignment="left">
            <ul>
              <li>
                Välj en allmän plats (gata, torg, natur eller annat område som
                är tillgängligt för alla) i Norrtälje kommun
              </li>
              <li>Välj om du vill dansa ensam, med en vän eller flera.</li>
              <li>
                Spela in en video med din mobil eller kamera. Videon ska vara
                kort, mellan 30 sekunder och 2 minuter.
              </li>
              <li>
                Dela videon med hashtag #norrtaljedance eller maila den till oss
                på{" "}
                <a href="mailto:challenge@norrtaljedance.com">
                  challenge@norrtaljedance.com
                </a>
                .
              </li>
              <li>Ange i posten var i Norrtälje du/ni dansar.</li>
            </ul>
          </Paragraph>
        </Content>
      </Section>

      <Section background="Dancing 3" id="section4">
        <Content>
          <InstructionsCarousel />
        </Content>
      </Section>

      <Section background="X" id="section5">
        <Content>
          <Headline>Dela din video med oss</Headline>
          <Paragraph>
            Posta din video med hashtagen #norrtaljedance på någon av
            plattformarna nedan, så är du automatiskt med i tävlingen!
          </Paragraph>
          <Spacer distance={40} />
          <TwoColumn>
            <a
              href="https://www.instagram.com/reactactions/"
              target="_blank"
              rel="noreferrer"
            >
              <FontIconWrapper>
                <FontIcon>#</FontIcon>
                <FontIconText>Instagram</FontIconText>
                <Spacer distance={20} />
                <Text size={breakpoint === "desktop" ? 20 : 18}>
                  <Uppercase>
                    <Bold>#</Bold>Norrtalje<Bold>Dance</Bold>
                  </Uppercase>
                </Text>
              </FontIconWrapper>
            </a>
            <a
              href="https://vm.tiktok.com/ZMejj2j65/"
              target="_blank"
              rel="noreferrer"
            >
              <FontIconWrapper>
                <FontIcon>#</FontIcon>
                <FontIconText>Tik Tok</FontIconText>
                <Spacer distance={20} />
                <Text size={breakpoint === "desktop" ? 20 : 18}>
                  <Uppercase>
                    <Bold>#</Bold>Norrtalje<Bold>Dance</Bold>
                  </Uppercase>
                </Text>
              </FontIconWrapper>
            </a>
            <a
              href="https://www.facebook.com/reactactions/"
              target="_blank"
              rel="noreferrer"
            >
              <FontIconWrapper>
                <FontIcon>#</FontIcon>
                <FontIconText>Facebook</FontIconText>
                <Spacer distance={20} />
                <Text size={breakpoint === "desktop" ? 20 : 18}>
                  <Uppercase>
                    <Bold>#</Bold>Norrtalje<Bold>Dance</Bold>
                  </Uppercase>
                </Text>
              </FontIconWrapper>
            </a>
            <a href="mailto:challenge@norrtaljedance.com">
              <FontIconWrapper>
                <FontIcon>@</FontIcon>
                <FontIconText>E-mail</FontIconText>
                <Spacer distance={20} />
                <Text size={breakpoint === "desktop" ? 20 : 14}>
                  <Uppercase>challenge@norrtaljedance.com</Uppercase>
                </Text>
              </FontIconWrapper>
            </a>
          </TwoColumn>
        </Content>
      </Section>

      <Section background="Dancing" id="section6">
        <Content align="right">
          <Headline alignment="left">Om tävlingen</Headline>
          <Paragraph alignment="left">
            Tävlingen har 3 kategorier: solo, duo och grupp.
            <br />
            Juryn är Kommunkoreograf ReAct! - Actions moving.
            <br />
            Kriterierna baseras på kreativitet, lekfullhet och val av plats.
            <br />
            Vinnarna släpps den 2 april på en onlinegala.
          </Paragraph>
        </Content>
      </Section>

      <Section background="Bench" id="section7">
        <Content align="left">
          <Headline alignment="left">Det här kan du vinna</Headline>
          <Paragraph alignment="left">
            <ul>
              <li>
                Få delta i en ReAct!-dansfilm med ett professionellt team för
                den ultimata vinnaren
              </li>
              <li>Kulturaktivitet och föreställningar till alla vinnare</li>
              <li>Goodies för alla</li>
              <li>Och mer!</li>
            </ul>
          </Paragraph>
        </Content>
      </Section>

      <Section background="White" id="section8">
        <Content>
          {breakpoint === "mobile" && (
            <>
              <Headline color="red">Kontakt</Headline>
              <Spacer distance={32} />
            </>
          )}
          <TwoColumn>
            <a
              href="https://reactactions.com/"
              target="_blank"
              rel="noreferrer"
            >
              <VerticalMiddleAlign>
                <img src={KokoLogo} style={{ marginRight: 64 }} />
                <img src={ReActLogo} />
              </VerticalMiddleAlign>
            </a>
            <div>
              {breakpoint === "desktop" && (
                <Headline color="red">Kontakt</Headline>
              )}
              <a href="mailto:challenge@norrtaljedance.com">
                <Paragraph color="black">
                  <Text color="black" size={breakpoint === "desktop" ? 20 : 14}>
                    <Uppercase>challenge@norrtaljedance.com</Uppercase>
                  </Text>
                </Paragraph>
              </a>
            </div>
          </TwoColumn>
        </Content>
      </Section>

      <Section background="Dancing 2" id="section9">
        <Content>
          <Headline color="white">
            Skicka nu! och senast den 31 mars 2021
          </Headline>
        </Content>
      </Section>

      <Footer />
    </div>
  );
}

export default App;
