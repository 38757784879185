import styled from "styled-components";
import { mobileBreakpointMediaQuery } from "utils";

const Wrapper = styled.div({
  display: "grid",
  gridTemplateColumns: "1fr 1fr",
  padding: "0 150px",
  columnGap: 80,
  rowGap: 50,
  [mobileBreakpointMediaQuery]: {
    padding: 0,
    columnGap: 0,
    rowGap: 32,
    gridTemplateColumns: "1fr",
  },
});

export const TwoColumn = ({ children }) => <Wrapper>{children}</Wrapper>;
