import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel as OriginalCarousel } from "react-responsive-carousel";
import styled from "styled-components";
import React from "react";
import {
  Bold,
  BREAKPOINTS,
  FontIcon,
  FontIconText,
  mobileBreakpointMediaQuery,
} from "utils";
import useBreakpoint from "use-breakpoint";

const Carousel = styled(OriginalCarousel)({
  width: 800,
  margin: "0 auto",
});

const Slide = styled.div({
  display: "grid",
  gridTemplateColumns: "1fr 1fr 1fr",
  padding: 80,
  columnGap: 80,
});

const Column = styled.div({
  [mobileBreakpointMediaQuery]: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
  },
});

const CustomTwoColumn = styled.div({
  display: "grid",
  gridTemplateColumns: "1fr 1fr",
  padding: "0 150px",
  columnGap: 80,
  [mobileBreakpointMediaQuery]: {
    padding: 0,
    columnGap: 0,
    rowGap: 32,
  },
});

export const InstructionsCarousel = () => {
  const { breakpoint } = useBreakpoint(BREAKPOINTS, "desktop");

  switch (breakpoint) {
    case "desktop": {
      return (
        <Carousel
          showThumbs={false}
          showStatus={false}
          showArrows={true}
          stopOnHover={true}
          swipeable={false}
          emulateTouch={false}
          autoPlay={true}
          interval={5000}
          infiniteLoop={true}
        >
          <Slide>
            <Column>
              <FontIcon>O</FontIcon>
              <FontIconText>
                Utomhus i<br />
                <Bold>
                  Norrtälje
                  <br />
                  kommun
                </Bold>
              </FontIconText>
            </Column>
            <Column>
              <FontIcon>&gt;</FontIcon>
              <FontIconText>
                Videons längd
                <br />
                <Bold>30 sek - 2 min</Bold>
              </FontIconText>
            </Column>
            <Column>
              <FontIcon>M</FontIcon>
              <FontIconText>
                Mellan
                <br />
                <Bold>1 - 31 mars</Bold>
              </FontIconText>
            </Column>
          </Slide>

          <Slide>
            <Column>
              <FontIcon>I</FontIcon>
              <FontIconText>
                <Bold>Solo</Bold>
              </FontIconText>
            </Column>
            <Column>
              <FontIcon>I I</FontIcon>
              <FontIconText>
                <Bold>Duett</Bold>
              </FontIconText>
            </Column>
            <Column>
              <FontIcon style={{ letterSpacing: -4 }}>II</FontIcon>
              <FontIconText>
                <Bold>Grupp</Bold>
              </FontIconText>
            </Column>
          </Slide>
        </Carousel>
      );
    }
    case "mobile":
    default: {
      return (
        <CustomTwoColumn>
          <Column>
            <FontIcon>O</FontIcon>
            <FontIconText>
              Utomhus i<br />
              <Bold>
                Norrtälje
                <br />
                kommun
              </Bold>
            </FontIconText>
          </Column>

          <Column>
            <FontIcon>I</FontIcon>
            <FontIconText>
              <Bold>Solo</Bold>
            </FontIconText>
          </Column>

          <Column>
            <FontIcon>&gt;</FontIcon>
            <FontIconText>
              Videons längd
              <br />
              <Bold>30 sek - 2 min</Bold>
            </FontIconText>
          </Column>

          <Column>
            <FontIcon>I I</FontIcon>
            <FontIconText>
              <Bold>Duett</Bold>
            </FontIconText>
          </Column>
          <Column>
            <FontIcon>M</FontIcon>
            <FontIconText>
              Mellan
              <br />
              <Bold>1 - 31 mars</Bold>
            </FontIconText>
          </Column>
          <Column>
            <FontIcon style={{ letterSpacing: -4 }}>II</FontIcon>
            <FontIconText>
              <Bold>Grupp</Bold>
            </FontIconText>
          </Column>
        </CustomTwoColumn>
      );
    }
  }
};
