import styled from "styled-components";
import pointImage from "img/timeline-point.svg";
import lineImage from "img/timeline-line.svg";
import {
  BREAKPOINTS,
  mobileBreakpointMediaQuery,
  palette,
  TripleSpaced,
} from "utils";
import useBreakpoint from "use-breakpoint";

const Grid = styled.div({
  display: "grid",
  gridTemplateColumns: "auto 1fr auto auto",
});

const Point = styled.div({
  display: "flex",
  flexDirection: "column",
});

const Content = styled.div({
  padding: 40,
  paddingTop: 0,
  paddingBottom: 0,
  [mobileBreakpointMediaQuery]: {
    padding: 20,
  },
});

const StyledPointImage = styled.img({});

const LineHeader = styled.div({
  flexGrow: 1,
  display: "block",
  width: "100%",
  height: 19,
  background: `url(${lineImage})`,
  backgroundSize: "19px 19px",
});

const LineContainer = styled.div({
  width: "100%",
  display: "flex",
  flexDirection: "row",
});

const PointHeader = () => <StyledPointImage src={pointImage} />;

const LinePoint = () => (
  <LineContainer>
    <LineHeader />
    <PointHeader />
    <LineHeader />
  </LineContainer>
);

const Line = () => (
  <LineContainer>
    <LineHeader />
  </LineContainer>
);

const HeadlineNumber = styled.div({
  textTransform: "uppercase",
  fontFamily: `"Monoton", cursive`,
  fontSize: 50,
  color: palette.red,
  fontWeight: 400,
  margin: "0 auto",
  textAlign: "center",
  marginBottom: -14,
});

const HeadlineText = styled.div({
  textTransform: "uppercase",
  fontFamily: `"Monoton", cursive`,
  fontSize: 35,
  color: palette.red,
  fontWeight: 400,
  margin: "0 auto",
  textAlign: "center",
  marginTop: 14,
});

const TextBelow = styled.p({
  fontWeight: 400,
  fontSize: 14,
  fontFamily: `"Montserrat", sans-serif`,
  color: "#fff",
  margin: "0 auto",
  textAlign: "center",
  lineHeight: 1.6,
  textTransform: "uppercase",
});

const Red = styled.span({
  color: palette.red,
});

const White = styled.span({
  color: "#fff",
});

export const Timeline = () => {
  const { breakpoint } = useBreakpoint(BREAKPOINTS, "desktop");

  return (
    <Grid>
      <Point>
        <LinePoint />
        <Content>
          <HeadlineNumber>1</HeadlineNumber>
          <TextBelow>
            <Red>Mars</Red>
            <br />
            start
          </TextBelow>
        </Content>
      </Point>

      {breakpoint === "desktop" && (
        <Point>
          <Line />
          <Content>
            <HeadlineText>
              <TripleSpaced>
                Dansa <White>&gt;</White> Filma <White>&gt;</White> Skicka
              </TripleSpaced>
            </HeadlineText>
          </Content>
        </Point>
      )}

      <Point>
        <LinePoint />
        <Content>
          <HeadlineNumber>31</HeadlineNumber>
          <TextBelow>
            <Red>Mars</Red>
            <br />
            slut
          </TextBelow>
        </Content>
      </Point>

      <Point>
        <LinePoint />
        <Content>
          <HeadlineNumber>2</HeadlineNumber>
          <TextBelow>
            <Red>April</Red>
            <br />
            vinnare
          </TextBelow>
        </Content>
      </Point>
    </Grid>
  );
};
