import FacebookIcon from "img/fb.svg";
import InstagramIcon from "img/insta.svg";
import React, { useState } from "react";
import styled from "styled-components";
import useBreakpoint from "use-breakpoint";
import { BREAKPOINTS, mobileBreakpointMediaQuery, palette } from "utils";
import HamburgerMenuImage from "img/hamburger-menu.svg";
import CloseHamburgerMenuImage from "img/close-hamburger-menu.png";
import Modal from "react-modal";
import { LogoImage } from "logo";

export const Link = styled.button({
  background: "none",
  border: "none",
  color: "#fff",
  fontWeight: 500,
  fontSize: 15,
  fontFamily: `"Montserrat", sans-serif`,
  marginLeft: 32,
  transition: "0.125s",
  cursor: "pointer",
  textTransform: "uppercase",
  ":hover": {
    color: "#231f20",
    fill: "#231f20",
  },
  [mobileBreakpointMediaQuery]: {
    marginLeft: 0,
    padding: 0,
    fontSize: 35,
    marginBottom: 22,
  },
});

const SocialMedia = styled.div({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  marginLeft: 64,
  width: 56,
  [mobileBreakpointMediaQuery]: {
    marginLeft: 0,
    width: 89,
    marginTop: 32,
  },
});

const HamburgerLink = styled.button({
  justifySelf: "flex-end",
  alignSelf: "center",
  cursor: "pointer",
  background: "none",
  border: "none",
  padding: 0,
});

const ModalLogoLink = styled.button({
  justifySelf: "flex-start",
  cursor: "pointer",
  background: "none",
  border: "none",
  padding: 0,
});

export const Nav = styled.nav({
  display: "flex",
  flexDirection: "row-reverse",
  alignItems: "center",
  justifyContent: "flex-start",
  height: "100%",
  [mobileBreakpointMediaQuery]: {
    flexDirection: "column-reverse",
    height: "auto",
    position: "relative",
    justifyContent: "center",
    paddingTop: 32,
    paddingBottom: 32,
  },
});

const StyledModal = styled(Modal)({
  display: "grid",
  gridTemplateRows: "auto 1fr 40px",
  background: palette.red,
  position: "fixed",
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  padding: 20,
});

export const switchTo = (elementId = "", skipHeaderAdjustment = false) => {
  var elementBounds = document
    .getElementById(elementId)
    ?.getBoundingClientRect();
  console.log("elementBounds", elementBounds);
  var headerBounds = document.getElementById("header")?.getBoundingClientRect();
  var headerAdjustment = skipHeaderAdjustment ? 0 : headerBounds?.bottom ?? 0;
  console.log("headerBounds", headerBounds);
  console.log("headerAdjustment", headerAdjustment);
  console.log("window.scrollY", window.scrollY);
  console.log(
    "result",
    (elementBounds?.top ?? 0) - headerAdjustment + window.scrollY
  );
  window.scrollTo({
    top: (elementBounds?.top ?? 0) - headerAdjustment + window.scrollY,
    behavior: "smooth",
  });
};

const HamburgerMenu = ({ isOpen, children }) => {
  return <StyledModal isOpen={isOpen}>{children}</StyledModal>;
};
Modal.setAppElement("#modal-container");

export const NavLinks = () => {
  const { breakpoint } = useBreakpoint(BREAKPOINTS, "desktop");
  const [isHamburgerMenuOpen, setIsHamburgerMenuOpen] = useState(false);

  const Links = () => (
    <>
      <SocialMedia>
        <a
          className="insta-link"
          href="https://www.instagram.com/reactactions/"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src={InstagramIcon}
            height={breakpoint === "mobile" ? 32 : 20}
            alt="Instagram"
          />
        </a>
        <a
          className="fb-link"
          href="https://www.facebook.com/reactactions"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src={FacebookIcon}
            height={breakpoint === "mobile" ? 32 : 20}
            alt="Facebook"
          />
        </a>
      </SocialMedia>
      <Link
        onClick={() => {
          switchTo("section8");
          if (breakpoint === "mobile") {
            setIsHamburgerMenuOpen(!isHamburgerMenuOpen);
          }
        }}
      >
        Kontakt
      </Link>
      <Link
        onClick={() => {
          switchTo("section7");
          if (breakpoint === "mobile") {
            setIsHamburgerMenuOpen(!isHamburgerMenuOpen);
          }
        }}
      >
        Priser
      </Link>
      <Link
        onClick={() => {
          switchTo("section6");
          if (breakpoint === "mobile") {
            setIsHamburgerMenuOpen(!isHamburgerMenuOpen);
          }
        }}
      >
        Om tävlingen
      </Link>
      <Link
        onClick={() => {
          switchTo("section3");
          if (breakpoint === "mobile") {
            setIsHamburgerMenuOpen(!isHamburgerMenuOpen);
          }
        }}
      >
        Regler
      </Link>
      <Link
        onClick={() => {
          switchTo("section2");
          if (breakpoint === "mobile") {
            setIsHamburgerMenuOpen(!isHamburgerMenuOpen);
          }
        }}
      >
        Utmaningen
      </Link>
    </>
  );

  switch (breakpoint) {
    case "desktop": {
      return (
        <Nav>
          <Links />
        </Nav>
      );
    }
    case "mobile":
    default: {
      return (
        <>
          <HamburgerLink
            onClick={() => {
              setIsHamburgerMenuOpen(!isHamburgerMenuOpen);
            }}
          >
            <img src={HamburgerMenuImage} alt="Open navigation" />
          </HamburgerLink>
          <HamburgerMenu isOpen={isHamburgerMenuOpen}>
            <ModalLogoLink
              onClick={() => {
                switchTo("section1", true);
                setIsHamburgerMenuOpen(!isHamburgerMenuOpen);
              }}
            >
              <LogoImage />
            </ModalLogoLink>
            <Nav>
              <Links />
            </Nav>
            <Link
              onClick={() => {
                setIsHamburgerMenuOpen(!isHamburgerMenuOpen);
              }}
              style={{ position: "absolute", top: 16, right: 20 }}
            >
              <img src={CloseHamburgerMenuImage} alt="Close navigation" />
            </Link>
          </HamburgerMenu>
        </>
      );
    }
  }
};
