import { Header } from "header";
import LegibilityGradient from "img/Gradiante red - legibility.png";
import BlackDecorationLines from "img/Black vertical lines.png";
import { MainHeadline } from "main-headline";
import React from "react";
import {
  BREAKPOINTS,
  mobileBreakpointMediaQuery,
  Spacer,
  tripleSpaces,
} from "utils";
import useBreakpoint from "use-breakpoint";
import styled from "styled-components";
import { Content } from "content";
import BackgroundImage from "img/BG_header@2x.jpg";

const Wrapper = styled.div({
  background: `url(${BackgroundImage})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  width: "100%",
  height: "100vh",
  position: "relative",
  zIndex: 3,
  [mobileBreakpointMediaQuery]: {
    height: "auto",
  },
});

const Grid = styled.div({
  display: "grid",
  gridTemplateRows: "1fr auto 1fr",
  height: "100%",
  position: "relative",
  zIndex: 3,
  [mobileBreakpointMediaQuery]: {
    height: "auto",
  },
});

const Columns = styled.div({
  display: "grid",
  gridTemplateColumns: "1fr auto",
  [mobileBreakpointMediaQuery]: {
    padding: "50px 0",
    gridTemplateColumns: "1fr",
  },
});

const TextPlacer = styled.div({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "center",
});

const Explanation = styled.h3({
  color: "#fff",
  fontSize: 26,
  fontFamily: `"Montserrat", sans-serif`,
  fontWeight: 500,
  margin: 0,
  marginTop: 4,
  marginBottom: 16,
  [mobileBreakpointMediaQuery]: {
    fontSize: 16,
  },
});

const Video = styled.div({
  width: 608,
  height: 342,
  [mobileBreakpointMediaQuery]: {
    width: 335,
    height: 188,
    margin: "0 auto",
  },
});

const HeaderLegibility = styled.img({
  height: "100%",
  minWidth: "70%",
  maxWidth: "100%",
  position: "absolute",
  zIndex: 2,
});

export const SplashSection = () => {
  const { breakpoint } = useBreakpoint(BREAKPOINTS, "desktop");

  return (
    <Wrapper>
      <HeaderLegibility src={LegibilityGradient} />
      <Grid>
        <div>&nbsp;</div>
        <Content>
          <Columns>
            <TextPlacer>
              <MainHeadline>
                {breakpoint === "desktop" ? (
                  <>
                    Gå ut
                    <br />
                    <span className="black">{tripleSpaces("och dansa")}</span>
                  </>
                ) : (
                  <>
                    Gå ut
                    <br />
                    <span className="black">
                      och
                      <br />
                      dansa
                    </span>
                  </>
                )}
              </MainHeadline>
              <Explanation>Gör en dansvideo utomhus, vinn priser</Explanation>
              <Spacer distance={breakpoint === "mobile" ? 16 : 0} />
            </TextPlacer>
            <Video>
              <iframe
                width="100%"
                height="100%"
                src="https://www.youtube-nocookie.com/embed/CLeMiy0ZEto?rel=0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                frameBorder="0"
                title="Norrtälje Dance Challenge promotion video"
              ></iframe>
            </Video>
          </Columns>
        </Content>
        <div className="empty"></div>
      </Grid>
      <img className="black-lines-decoration" src={BlackDecorationLines} />
    </Wrapper>
  );
};
