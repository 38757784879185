import { Content } from "content";
import styled from "styled-components";
import StockholmLogo from "img/stockholm-logo.svg";
import NorrtaljeLogo from "img/norrtalje-logo.svg";
import { Paragraph } from "text";
import { mobileBreakpointMediaQuery, Uppercase } from "utils";

const Wrapper = styled.div({
  background: "#231f20",
  paddingTop: 20,
  paddingBottom: 20,
});

const Flex = styled.div({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  margin: "0 150px",
  [mobileBreakpointMediaQuery]: {
    margin: 0,
    flexDirection: "column",
  },
});

const Logos = styled.div({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  [mobileBreakpointMediaQuery]: {
    marginTop: 16,
    marginBottom: 0,
  },
});

const Logo = styled.img({
  marginleft: "32",
});

export const Footer = () => (
  <Wrapper>
    <Content>
      <Flex>
        <a href="https://reactactions.com/" target="_blank" rel="noreferrer">
          <Paragraph alignment="left">
            <Uppercase>Copyright ©2021 ReAct! - Actions moving</Uppercase>
          </Paragraph>
        </a>
        <Logos>
          <a
            href="https://www.norrtalje.se/koko"
            target="_blank"
            rel="noreferrer"
          >
            <Logo src={NorrtaljeLogo} />
          </a>
          <a
            href="https://www.kultur.sll.se/danscirkus"
            target="_blank"
            rel="noreferrer"
          >
            <Logo src={StockholmLogo} style={{ marginLeft: 32 }} />
          </a>
        </Logos>
      </Flex>
    </Content>
  </Wrapper>
);
