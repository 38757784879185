import styled from "styled-components";
import { mobileBreakpointMediaQuery, tripleSpaceChildren } from "utils";

const Component = styled.h2({
  whiteSpace: "pre",
  textTransform: "uppercase",
  fontFamily: `"Monoton", cursive`,
  fontSize: 70,
  color: "#fff",
  fontWeight: 400,
  margin: 0,
  lineHeight: 1.2,
  [mobileBreakpointMediaQuery]: {
    lineHeight: 1.1,
  },
});

export const MainHeadline = ({ children }) => {
  return <Component>{tripleSpaceChildren(children)}</Component>;
};
