import styled from "styled-components";
import { mobileBreakpointMediaQuery } from "utils";

const Component = styled.div<{ $noPadding: boolean }>(($noPadding) => ({
  width: "100%",
  maxWidth: 1366,
  margin: "0 auto",
  padding: $noPadding ? "0 32px" : "32px 32px",
  position: "relative",
  [mobileBreakpointMediaQuery]: {
    padding: $noPadding ? "0 20px" : "20px 20px",
  },
}));

const SmallLeft = styled.div({
  display: "block",
  margin: "0 auto 0 0",
  width: 600,
  [mobileBreakpointMediaQuery]: {
    width: "100%",
  },
});
const SmallRight = styled.div({
  display: "block",
  margin: "0 0 0 auto",
  width: 600,
  [mobileBreakpointMediaQuery]: {
    width: "100%",
  },
});

type align = "left" | "right" | "full";

type props = {
  children: any;
  noPadding?: boolean;
  align?: align;
};

export const Content = ({
  children,
  noPadding = false,
  align = "full",
}: props) => {
  switch (align) {
    case "full":
      return <Component {...{ $noPadding: noPadding }}>{children}</Component>;
    case "left":
      return (
        <Component {...{ $noPadding: noPadding }}>
          <SmallLeft>{children}</SmallLeft>
        </Component>
      );
    case "right":
      return (
        <Component {...{ $noPadding: noPadding }}>
          <SmallRight>{children}</SmallRight>
        </Component>
      );
  }
};
