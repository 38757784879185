import styled from "styled-components";
import { mobileBreakpointMediaQuery, palette } from "utils";

type alignment = "left" | "center";
type color = "white" | "black" | "red";

const StyledP = styled.p<{ $alignment: alignment; color: color }>(
  ({ $alignment, color }) => ({
    fontWeight: 400,
    fontSize: 14,
    fontFamily: `"Montserrat", sans-serif`,
    color: color === "red" ? palette.red : color,
    width: 600,
    margin: $alignment === "center" ? "0 auto" : "0",
    textAlign: $alignment,
    lineHeight: 2.1,
    [mobileBreakpointMediaQuery]: {
      width: "100%",
      maxWidth: 600,
    },
  })
);

type props = {
  children: any;
  alignment?: alignment;
  color?: color;
};

export const Paragraph = ({
  children,
  alignment = "center",
  color = "white",
}: props) => {
  return <StyledP {...{ $alignment: alignment, color }}>{children}</StyledP>;
};

export const Text = styled.span<{ size?: 14 | 18 | 20; color?: color }>(
  ({ size = 14, color = "white" }) => ({
    fontWeight: 400,
    fontSize: size,
    fontFamily: `"Montserrat", sans-serif`,
    color: color === "red" ? palette.red : color,
    lineHeight: 1.1,
  })
);
