import styled from "styled-components";
import backgroundX from "img/black-x-background.svg";
import backgroundN from "img/black-n-background.svg";
import backgroundO from "img/red-o-background.svg";
import backgroundDancing from "img/red-dancing-background@2x.jpg";
import backgroundDancing2 from "img/red-dancing2-background@2x.jpg";
import backgroundDancing3 from "img/black-dancing3-background@2x.jpg";
import backgroundBench from "img/black-bench-background@2x.jpg";

type background =
  | "N"
  | "X"
  | "O"
  | "Dancing"
  | "Dancing 2"
  | "Dancing 3"
  | "Bench"
  | "White";

const getBackground = (background: background) => {
  switch (background) {
    case "N":
      return `url(${backgroundN})`;
    case "X":
      return `url(${backgroundX})`;
    case "O":
      return `url(${backgroundO})`;
    case "Dancing":
      return `url(${backgroundDancing})`;
    case "Dancing 2":
      return `url(${backgroundDancing2})`;
    case "Dancing 3":
      return `url(${backgroundDancing3})`;
    case "Bench":
      return `url(${backgroundBench})`;
    case "White":
      return `#fff`;
  }
};

const Container = styled.section<{ $background: background }>(
  ({ $background }) => ({
    background: getBackground($background),
    backgroundSize: "cover",
    backgroundPosition: "center",
    display: "block",
    paddingTop: 88,
    paddingBottom: 80,
    position: "relative",
  })
);

type props = {
  background: background;
  children: React.ReactNode;
  id: string;
};

export const Section = ({ children, background, id }: props) => {
  return <Container {...{ $background: background, id }}>{children}</Container>;
};
